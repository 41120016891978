import { ec } from 'elliptic';
import { SHA256, AES, enc } from 'crypto-js';

const EC = new ec('curve25519');

/** Crypto key pair. */
export type KeyPair = Readonly<{

	/** Private key. */
	privateKey: string;

	/** Public key. */
	publicKey: string;
}>;

/** Generate key pair. */
export function generateKeyPair(): KeyPair {
	const keyPair = EC.genKeyPair();
	const privateKey = keyPair.getPrivate('hex');
	const publicKey = keyPair.getPublic('hex');

	return { privateKey, publicKey };
}

/**
 * Perform key exchange.
 * @param otherUserPublicKey Other user public key.
 * @param privateKey Private key.
 */
export function deriveSharedSecret(otherUserPublicKey: string, privateKey: string): string {
	const keyPair = EC.keyFromPrivate(privateKey, 'hex');
	const serverPublicKeyPoint = EC.keyFromPublic(otherUserPublicKey, 'hex').getPublic();
	const sharedSecret = keyPair.derive(serverPublicKeyPoint).toString(16);

	return SHA256(sharedSecret).toString();
}

/**
 * Encrypt message.
 * @param message Message.
 * @param sharedKey Shared key.
 */
export function encryptMessage(message: string, sharedKey: string): string {
	return AES.encrypt(message, sharedKey).toString();
}

/**
 * Decrypt message.
 * @param encryptedMessage Encrypted message.
 * @param sharedKey Shared key.
 */
export function decryptMessage(encryptedMessage: string, sharedKey: string): string {
	return AES.decrypt(encryptedMessage, sharedKey).toString(enc.Utf8);
}

/**
 * Encrypt file.
 * @param file File to encrypt.
 * @param sharedKey Shared key.
 */
export async function encryptFile(file: File, sharedKey: string): Promise<File> {
	const fileArrayBuffer = await file.arrayBuffer();
	const uint8Array = new Uint8Array(fileArrayBuffer);

	let binary = '';
	uint8Array.forEach(byte => {
		binary += String.fromCharCode(byte);
	});
	const base64 = btoa(binary);
	const encrypted = AES.encrypt(base64, sharedKey).toString();

	const encryptedBlob = new Blob([encrypted], { type: 'application/octet-stream' });
	return new File([encryptedBlob], `${file.name}.enc`, { type: 'application/octet-stream' });
}

/**
 * Decrypt file.
 * @param file Decrypted file.
 * @param sharedKey Shared key.
 * @param fileType File type.
 */
export async function decryptFile(
	file: File,
	sharedKey: string,
	fileType: string,
): Promise<{ url: string; teardown: () => void; }> {
	const encryptedText = await file.text();
	const decrypted = AES.decrypt(encryptedText, sharedKey);
	const decryptedBase64 = decrypted.toString(enc.Utf8);

	const binary = atob(decryptedBase64);
	const bytes = new Uint8Array(binary.length);
	for (let i = 0; i < binary.length; i++) {
		bytes[i] = binary.charCodeAt(i);
	}

	const fileBlob = new Blob([bytes], { type: fileType });

	const url = URL.createObjectURL(fileBlob);
	return { url, teardown: () => URL.revokeObjectURL(url) };
}
