import { DateUtils } from '../../utils/date-utils';
import { assertNonNullWithReturn } from '../../utils/assert-non-null';

import { SecureChat } from './secure-chat';
import { NonSecureChat } from './non-secure-chat';

/** Chat. */
export type Chat = SecureChat | NonSecureChat;

export namespace Chat {

	/**
	 * A function that determines the order of the elements.
	 * @param chat1 First chat.
	 * @param chat2 Second chat.
	 */
	export function compareFn(chat1: Chat, chat2: Chat): number {
		const chat1LatestMessageDate = chat1.latestMessage?.date ?? chat1.conversation.dateCreated;
		const chat2LatestMessageDate = chat2.latestMessage?.date ?? chat2.conversation.dateCreated;

		if (chat1LatestMessageDate == null && chat2LatestMessageDate == null) {
			// Indicates that `chat1` and `chat2` are considered equal.
			return 0;
		} else if (chat1LatestMessageDate == null && chat2LatestMessageDate != null) {
			// Indicates that `chat1` should come after `chat2`.
			return 1;
		} else if (chat1LatestMessageDate != null && chat2LatestMessageDate == null) {
			// Indicates that `chat1` should come before `chat2`.
			return -1;
		}

		return DateUtils.compare(
			assertNonNullWithReturn(chat1LatestMessageDate),
			assertNonNullWithReturn(chat2LatestMessageDate),
		);
	}
}
