import { Message } from '@twilio/conversations';
import { defer, map, Observable, switchMap } from 'rxjs';
import { Emoji } from '@wndr/common/shared/features/emoji/emoji';

import { ContentType } from '../content-type';
import { assertNonNullWithReturn } from '../../utils/assert-non-null';
import { isMessageAttributes } from '../../twilio/message-attributes';
import { decryptFile, decryptMessage } from '../../utils/crypto';

/** Media message attachment. */
export type MediaAttachment = Readonly<{

	/** Attachment URL. */
	url: Observable<string>;

	/** Content type. */
	type: ContentType;

	/** Name. */
	name: string;
}>;

/** Secure media attachment. */
export type SecureMediaAttachment = Readonly<{

	/** Content type. */
	type: ContentType;

	/** Name. */
	name: string;

	/** ID. */
	id: number;
}>;

/** Chat message's reaction. */
export type MessageReaction = Readonly<{

	/** Emoji. */
	emoji: Emoji;

	/** Users identifiers who reacted. */
	users: ReadonlyArray<string>;
}>;

/** Chat message. */
export type ChatMessage = Readonly<{

	/** Message instance. */
	message: Message;

	/** Updated date. */
	updatedDate: Date | null;

	/** Body. */
	body: string;

	/** Author ID. */
	authorId: string;

	/** Attachments URLs. */
	attachments: ReadonlyArray<MediaAttachment>;

	/** ID. */
	id: number;

	/** Reactions. */
	reactions: ReadonlyArray<MessageReaction>;

	/** SID of the message being replied to. */
	replyToSid?: string;
}>;

export namespace ChatMessage {

	/**
	 * Map from twilio message instance to domain.
	 * @param message Twilio message instance.
	 * @param sharedKey Shared key.
	 */
	export function fromMessage(message: Message, sharedKey: string | null): ChatMessage {
		const messageAttributes = isMessageAttributes(message.attributes) ? message.attributes : undefined;
		const messageBody = message.body ?? '';

		return {
			message,
			authorId: assertNonNullWithReturn(message.author),
			body: sharedKey ? decryptMessage(messageBody, sharedKey) : messageBody,
			id: message.index,
			updatedDate: message.dateUpdated,
			attachments: prepareAttachments(message, sharedKey),
			reactions: messageAttributes?.reactions ?? [],
			replyToSid: messageAttributes?.replyTo,
		};
	}

	/**
	 * Prepare attachments of the message.
	 * @param message Message instance.
	 * @param sharedKey Shared key.
	 */
	function prepareAttachments(message: Message, sharedKey: string | null): MediaAttachment[] {
		if (message.attachedMedia && sharedKey == null) {
			return message.attachedMedia.map(m => ({
				type: m.contentType as ContentType,
				name: m.filename ?? '',
				url: defer(() => m.getContentTemporaryUrl()).pipe(map(value => value ?? '')),
			}));
		}

		if (message.attachedMedia && sharedKey) {
			return message.attachedMedia.map(m => ({
				type: m.contentType as ContentType,
				name: m.filename ?? '',
				url: defer(() => m.getContentTemporaryUrl()).pipe(
					map(url => url ?? ''),
					switchMap(url => fetch(url)),
					switchMap(response => response.blob()),
					switchMap(blob => decryptFile(new File([blob], 'encrypted'), sharedKey, m.contentType)),
					map(({ url }) => url),
				),
			}));
		}

		return [];
	}
}
