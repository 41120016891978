/* eslint-disable @typescript-eslint/naming-convention */
import { z } from 'zod';

/** Schema to validate chat attributes. */
export const chatAttributesSchema = z.object({
	chat_id: z.number(),

	/** Whether end-to-end encryption is enabled for this chat. */
	is_encrypted: z
		.boolean()
		.optional()
		.default(false),

	/** Whether the chat is closed or not. */
	is_closed: z
		.boolean()
		.optional()
		.default(false),

	/** Participants. */
	users: z.array(
		z.object({
			/** Participant ID. */
			id: z.number(),

			/**
			 * Device name.
			 * @example Sang’s Iphone 15 Pro.
			 */
			device_name: z
				.string()
				.nullish()
				.default(null),

			/**	Device unique identifier. */
			device_token: z
				.string()
				.nullish()
				.default(null),

			/** Whether is chat deleted by the user. */
			is_chat_deleted: z
				.boolean()
				.optional()
				.default(false),
		}),
	),
});

/** Chat attributes. */
export type ChatAttributes = z.infer<typeof chatAttributesSchema>;
