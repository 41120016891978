import { Injectable } from '@angular/core';

import { ChatMetaDto } from '../dtos/chat/chat-meta.dto';
import { ChatMeta } from '../models/chat/chat-meta';

import { FullChatDto } from '../dtos/chat/chat.dto';
import { FullChat } from '../models/chat/full-chat';

import { MapperFromDto } from './mappers';

/** Chat meta mapper. */
@Injectable({ providedIn: 'root' })
export class ChatMetaMapper implements MapperFromDto<ChatMetaDto, ChatMeta> {

	/** @inheritdoc */
	public fromDto(dto: ChatMetaDto): ChatMeta {
		return {
			id: dto.id,
			twilioSid: dto.conversation_sid,
			isClosed: dto.is_closed,
			isEncrypted: dto.is_encrypted,
		};
	}

	/**
	 * Map from full chat dto to domain model.
	 * @param dto Full chat dto.
	 */
	public fromFullChatDto(dto: FullChatDto): FullChat {
		return {
			...this.fromDto(dto),
			participants: dto.participants.map(p => ({ deviceToken: p.device_token ?? '' })),
		};
	}
}
