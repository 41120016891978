import { Message } from '@twilio/conversations';

import { isGiftAttrubutes } from '../../twilio/gift-attributes';
import { isCallAttributes } from '../../twilio/call-attributes';

import { ChatGiftMessage } from './chat-gift-message';
import { ChatMessage } from './chat-message';
import { ChatCallMessage } from './chat-call-message';

/**
 * Create ChatMessage object from twilio Message.
 * @param message Twilio message instance.
 * @param sharedKey Shared key.
 */
export function createChatMessage(message: Message, sharedKey: string | null): ChatMessage {
	if (isGiftAttrubutes(message.attributes)) {
		return ChatGiftMessage.fromMessage(message, sharedKey);
	}
	if (isCallAttributes(message.attributes)) {
		return ChatCallMessage.fromMessage(message, sharedKey);
	}
	return ChatMessage.fromMessage(message, sharedKey);
}
