import { JSONValue } from '@twilio/conversations';
import { z } from 'zod';

import { reactionsAttributeSchema } from './reactions-attribute';

/** Message attributes validation schema. */
const messageAttributesSchema = z.object({
	/** Reactions. */
	reactions: z.array(reactionsAttributeSchema).optional(),

	/** SID of the message being replied to. */
	replyTo: z.string().optional(),

	/** Secure media attachment. */
	attachments: z.array(
		z.object({

			/** Content type. */
			type: z.string(),

			/** Attachment ID. */
			id: z.string(),

			/** Name. */
			name: z.string(),
		}),
	).optional(),
});

/** Message attributes of twilio Message object. */
export type MessageAttributes = Readonly<z.infer<typeof messageAttributesSchema>>;

/**
 * Type-guard for MessageAttributes.
 * @param attributes Attributes.
 */
export function isMessageAttributes(attributes: JSONValue): attributes is MessageAttributes {
	let attribute = attributes;
	if (Array.isArray(attributes)) {
		if (attributes.length === 0) {
			return false;
		}
		attribute = attributes[0];
	}
	return messageAttributesSchema.safeParse(attribute).success;
}
