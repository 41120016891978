import { Injectable } from '@angular/core';

import { ChatCredentialsDto } from '../dtos/chat/chat-credentials.dto';
import { ChatCredentials } from '../models/chat/chat-credentials';
import { ConversationUserAttributesDto } from '../dtos/chat/conversation-user-attributes.dto';
import { ChatSidRequestDto } from '../dtos/chat/chat.dto';
import { User } from '../models/user';
import { ConversationUser } from '../models/chat/conversation-user';

/** Provide transform functions for chat models. */
@Injectable({ providedIn: 'root' })
export class ChatMapper {

	/**
	 * Transform chat credentials dto to domain.
	 * @param dto Chat credentials dto.
	 */
	public fromCredentialsDto(dto: ChatCredentialsDto): ChatCredentials {
		return {
			authToken: dto.token,
		};
	}

	/**
	 * Transform conversation user dto to domain.
	 * @param dto Conversation user attributes dto.
	 */
	public fromConversationUserAttributesDto(dto: ConversationUserAttributesDto): ConversationUser {
		return {
			id: typeof dto.id === 'string' ? dto.id : dto.id.toString(),
			firstName: dto.first_name,
			lastName: dto.last_name,
			avatarUrl: dto.avatar ?? '',
			username: dto.username,
			preferredName: dto.preferred_name ?? '',
			initials: dto.initials ?? '',
			isGuest: typeof dto.id === 'string',
			clinicianType: dto.clinician_type ?? null,
		};
	}

	/**
	 * Transform data to chat sid request body.
	 * @param id User ID.
	 */
	public toSidRequestDto(id: User['id']): ChatSidRequestDto {
		return {
			with_user: id,
		};
	}
}
