import { Message } from '@twilio/conversations';

import { isCallAttributes } from '../../twilio/call-attributes';

import { Session } from '../session';

import { ChatMessage } from './chat-message';

/** Chat message about call. */
export type ChatCallMessage = ChatMessage & Readonly<{

	/** Whether the call is in progress. */
	inProgress: boolean;

	/** Whether it's a video call. */
	isVideo: boolean;

	/** Consultation with active call. */
	consultationId: Session['id'];
}>;

export namespace ChatCallMessage {

	/**
	 * Map from chat message to chat gift message.
	 * @param message Chat message.
	 * @param sharedKey Shared key.
	 */
	export function fromMessage(message: Message, sharedKey: string | null): ChatCallMessage {

		if (isCallAttributes(message.attributes)) {
			return {
				...ChatMessage.fromMessage(message, sharedKey),
				inProgress: 'is_incoming_call' in message.attributes,
				isVideo: 'is_video_call' in message.attributes ? message.attributes.is_video_call === 'true' : false,
				consultationId: message.attributes.consultation_id,
			};
		}

		throw new Error('Invalid message type.');
	}

	/**
	 * Type guard for ChatGiftMessage.
	 * @param chatMessage ChatMessage instance.
	 */
	export function isChatCallMessage(chatMessage: ChatMessage): chatMessage is ChatCallMessage {
		return 'inProgress' in (chatMessage as unknown as ChatCallMessage);
	}
}
